body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FONTS  */

@font-face {
  font-family: Popins-regular;
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Popins-Medium;
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Popins-SemiBold;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Comforta-Regular;
  src: url("./fonts/Comfortaa/Comfortaa-Regular.ttf");
}


.textPrimaryLanding {
  margin-top: 2.125rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  font-family: Popins-regular;
  color: #123164
}


.textPrimaryLabelInput {

  font-family: Popins-regular;
  color: black;
  font-weight: 400;
  font-size: 0.875rem;

}

.textPrimaryInput {


  font-family: Popins-regular;
  font-weight: 500;
  font-size: 0.975rem;
  padding-left: 0.938rem;
  color: #5c5b5b;
}

.textPrimartyListInventary {

  font-family: Popins-SemiBold;
  font-size: 11px;
  position: relative;
  text-align: center;
}

.textSecondaryListInventary {
  font-family: Popins-regular;
  text-align: center;
  color: #6A6A6A;
  font-size: 12px;

  position: absolute;
  top: 30px;
}

.textFilterCount {
  font-family: Popins-regular;
  color: #000;
  margin-bottom: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textFilterCountSelect {
  font-family: Popins-regular;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.215px;
  letter-spacing: 0.202px;
  margin-bottom: 0;
}

.textSelectFilter {
  color: #B1B2B2;
  font-family: Popins-regular;
  margin-bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.textNumberLines {
  color: #000;
  font-family: Popins-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.textInputPrimaryFilter {
  font-family: Popins-regular;
  font-size: 0, 875rem;
  color: #7D7D7D;
}

.image_preview_base64{
  cursor: pointer;
  transition: all .2s;
}
.image_preview_base64:hover{
  box-shadow:   5px rgba(0, 0, 0, 0.338);
  transform: translate(0%,-6%);
  
}

.textCardUserTable {
  font-family: Popins-regular;
  font-weight: 600;
  font-size: 14px;
}

.textErrorInput {

  color: red;
  width: 220px;

  font-family: Popins-regular;
  position: absolute;
  top: 90px;
  font-size: 0.975rem;

}

.textPrimaryButton {

  color: white;
  font-family: Popins-regular;
  font-weight: 500;
  font-size: 1.125rem;
}



.textCheckedSidebar {

  color: white;
  font-family: Popins-Medium;
  font-size: 1rem;
  position: relative;
  top: 8px;
  margin-left: 12px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.textNotCheckedSidebar {

  color: #123164;
  font-family: Popins-Medium;
  font-size: 1rem;
  position: relative;
  top: 8px;
  white-space: nowrap;
  margin-left: 12px;
  margin-bottom: 10px;
}


.textPrimaryHeaderDashboard {
  font-family: Popins-SemiBold;
  font-size: 1.875rem;
  color: #123164;

}

.textPrimaryResumeProgress {

  font-family: Popins-Medium;
  font-size: 0.975rem;
}

.textBtnFilterManager {
  
  font-family: Popins-Medium;
  font-weight: 500;
  font-size: 15px;
}

.textPositionTableListManager {

  color: #000000;
  font-family: Popins-SemiBold;
  position: relative;
  top: 8px;
  margin-left: 10px;
  margin-right: 3px;
  font-size: 12px;
}

.textPrimaryInputFilterManager {
  font-family: Popins-regular;
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
}

.modal-dialog-erros {

  width: 375px;
  margin: 30px auto;
  border-radius: 20px;
}

.textReasonFilterManager {

  color: #123164;
  font-family: Popins-Medium;
  font-size: 1rem;
  cursor: pointer;

}

.textBtnModalFilterManager {
  color: white;
  font-family: Popins-Medium;
  font-weight: 500;
  font-size: 15px;

}

.textCountInputFilterManager {

  color: white;
  font-family: Popins-Medium;
  font-size: 1.313rem;
}

.textModalFilterManager {

  font-size: 18px;
  font-family: Popins-Medium;
  position: relative;
  top: 7px;
  right: 30px;


}

.textNameManageMentPage {

  font-family: Popins-regular;
  color: #000000;
  font-size: 12px;
  position: relative;
  top: 8px;
  margin-left: 11px;
  margin-right: 10px;
}

.textBtnTableListStock {

  color: white;
  font-family: Popins-Medium;
  font-size: 0.93rem;

}

.textNameTableListUserColum2 {

  font-family: Popins-Medium;
  color: #6A6A6A;
  font-size: 0.87rem;
  text-align: center;
  width: 220px;
  position: relative;
  top: -6px;

}

.textNameTableListUser {

  font-family: Popins-Medium;
  color: #6A6A6A;
  font-size: 0.87rem;
  text-align: center;
  width: 220px;
  position: relative;
  top: -6px;
}

.scrolltest {

  position: fixed;
  width: 100%;
  bottom: 20px;
  transform: translate(-1%, -10%);
}

.errorMessage {

  font-family: Popins-regular;
  color: red;
  font-size: 0.9rem;
  position: absolute;
  top: 84px;
  left: 2px;

}


.textNameTableListManager {

  font-family: Popins-Regular;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  width: 128px;
  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
  overflow: hidden; /* Oculta qualquer conteúdo que ultrapasse a largura do contêiner */
  text-overflow: ellipsis; /* Adiciona reticências (...) para indicar que o texto foi cortado */
  position: relative;
  top: 8px;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;

}

.notFoundIcon{
  object-fit: contain;
  width: 420px;
}

.container_image_not_found{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container_image_not_found > p{
  font-family: Popins-Medium;
  font-size: 1.2rem;

}

.containerLoadingGif{
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top:0;
  align-items: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #0000006c;
}
.containerLoadingGif > img{
  width: 120px;
  height: 120px;
}

.textNameTableListManager2 {

  font-family: Popins-Regular;
  color: #000000;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  top: 8px;
  margin-right: 3px;

}


.textPrimaryTableListAddStock {

  font-family: Popins-Medium;
  color: #000000;

  font-size: 1.125rem;


}

.textFileCvg {
  font-family: Popins-Medium;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textLabelInputSelectFilterManager {

  font-family: Popins-Medium;
  color: #000000;
  font-size: 14px;
  margin-bottom: 14px;
  font-weight: 500px;
}

.textSelect {
  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: Popins-Medium;
  font-size: 0.955rem;

}

.textBtnRemove {

  font-family: Popins-Medium;
  color: #EA0000;
  font-size: 0.93rem;
}

.textSelectFilterManager {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #797979;

  font-family: Popins-Medium;
  font-size: 0.955rem;

}

.textConfirmationRemove {
  font-family: Popins-regular;
  font-size: 1.12rem;
  text-align: center;
  margin-top: 1.37rem;
}

.textClientInfoHeader {
  margin-top: 15px;
  font-size: 0.9rem;
  font-family: Comforta-Regular;
  position: relative;
  right: 12px;
}


.textPrimaryCount {

  font-family: Popins-Medium;
  color: #000000;
  position: relative;
  top: 4px;
  left: 10px;
  font-weight: 500;
  font-size: 0.75rem;
}

.textPercentageCount {

  font-family: Popins-Medium;
  font-size: 0.75rem;
  font-weight: 500;
  position: relative;
  top: -5px;
  color: #545454;
  text-align: center;
}

.textBtnFilterManagerModal {
  font-family: Popins-Medium;
  font-size: 0.938rem;
  color: white;

}

.textCardDivergences {
  font-family: Popins-regular;
  font-size: 0.87rem;
  color: #000000;
  position: relative;
  top: 5px;
}

.textCountNumberCardDivergence {

  font-family: Popins-regular;
  font-size: 0.87rem;
  color: #000000;
  position: absolute;
  top: 34px;
  right: -19px;
}

.textPrimaryModalFilterManager {

  font-family: Popins-Medium;
  font-size: 1.125rem;
  margin-left: 18px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px;
  background: #D8D8D8;
  border-radius: 50px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #D8D8D8;
  border-radius: 10px;
  height: 35px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.33);
  border-radius: 10px;
}


.scrollParticipantsv::-webkit-scrollbar {
  width: 9px;
  /* Largura da barra de rolagem vertical */
  height: 5px;
  /* Altura da barra de rolagem horizontal */
}

/* Estilo do preenchimento da barra de rolagem */
.scrollParticipantsv::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Cor do preenchimento da barra de rolagem */
  border-radius: 4px;
  /* Raio de borda do preenchimento */
}

/* Estilo do fundo da barra de rolagem */
.scrollParticipantsv::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Cor do fundo da barra de rolagem */
}


.semi_circular_slider {

  margin-top: -20px;
  position: relative;

}

.textContDashboard {
  position: relative;
  top: 6px;
  left: 9px;
  font-family: Popins-SemiBold;
  font-size: 0.75rem;
}

.textContPercentage {

  position: relative;
  top: 4px;
  left: 15px;
  text-align: left;
  font-size: 0.913rem;
  color: #545454;
}

.modal-filter-table-list-user {

  width: 43vw;   /* Occupy the 90% of the screen width */
  max-width: 90vw;

}

.textModalTableListUserEditPrimary {
  font-family: Popins-Medium;
  color: #030303;
  font-size: 1.12rem;
}

.textModalTableListUserEditSecondary {
  font-family: Popins-Medium;
  color: #EA0000;
  font-weight: 500;
  font-size: 1.12rem;
}

.textModalTableListUserApprove {
  font-family: Popins-Medium;
  color: #00ea10;
  font-weight: 500;
  font-size: 1.12rem;
}


.textContageNewUsers {
  font-family: Popins-Medium;
  font-weight: 500;
  font-size: 0.9375rem;
  margin-bottom: 0;
}


.modal-filter-table-list-user-edit {

  width: 24.414vw;
  max-width: 24.414vw;
  border-radius: 20px;
}


.modal-filter-manager {

  width: 26.814vw;
}

.modal-filter-upload {

  width: 24.414vw;


}

.text_semi_circular_slider {
  position: relative;
  top: -140px;
  font-size: 1.3rem;
  text-align: center;
}

.semi_circular_slider path:nth-child(1) {
  fill: none;
  stroke: #cecece;
  stroke-width: 13px;
}

.semi_circular_slider path:nth-child(2) {
  fill: none;
  stroke: #123164;
  stroke-width: 13px;
}

circle {
  fill: #123164;
  stroke: #fff;
  stroke-width: 5px
}